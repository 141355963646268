import ProjectPolaroid from '../../components/ProjectPolaroid';
import './Works.css';
import CSA from '../../assets/CSA.png';
import Pickle from '../../assets/Pickle.png';
import Smart from '../../assets/Smart.png';
import UniBuzzy from '../../assets/UniBuzzy.png';
import Xray from '../../assets/Xray.png';
import sellRaze from '../../assets/sellRaze.png'

function Works() {
  const projects = [
    {
      title: 'SellRaze',
      description: (
        <>
      <p>
        SellRaze is an <strong>AI-powered e-commerce platform</strong> that enables users to instantly list items across 
        multiple marketplaces by simply uploading a photo or barcode. The app automates the entire listing process, 
        generating optimized posts on sites like <strong>eBay, Facebook, Depop,</strong> and <strong>Mercari</strong>, allowing sellers 
        to reach a broader audience with ease.
      </p>
      <p>
        Currently, I am working on <strong>transforming</strong> the existing mobile app, initially built with <strong>React Native</strong> and <strong>TypeScript</strong>, 
        into a fully functional desktop application. This involves adapting mobile-specific functionality and UI elements to fit the larger screen, 
        while maintaining a seamless user experience. The desktop version is developed using <strong>TypeScript, React,</strong> and <strong>Tailwind CSS</strong>, 
        and incorporates optimized design patterns and responsive layouts tailored to desktop workflows, allowing for efficient cross-platform synchronization 
        and streamlined management of sales, shipping, and customer interactions.
      </p>
    </>
      ),
      imageSrc: sellRaze,
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Full-stack Development', color: '#A6CBF5' },
      ],
      projectLink: 'https://www.sellraze.com/',
    },
    {
      title: 'AwareHome SmartBathroom Dashboard',
      description: (
        <>
          <p>
            This is a web-app that allows <strong>occupational therapists</strong> to analyze 
            and interact with the data coming from sensors within the SmartSeat to monitor patient
            movements and assess the physical health of older adults.
          </p>
          <p>
            The project utilizes <strong>ReactJS</strong> for the frontend, with a <strong>Flask API</strong> backend.
            Data management is handled through <strong>InfluxDB</strong> and real-time updates are managed via 
            <strong> HomeAssistant</strong>. Graphing capabilities are done though syncing the data to a <strong>Grafana </strong>
             dashdoard to analyze data.
          </p>
        </>
      ),
      imageSrc: Smart,
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Full-stack Development', color: '#A6CBF5' },
        { name: 'Research', color: '#93D375' },
        { name: 'Design', color: '#67C6C0' }
      ],
      projectLink: 'https://nathan-lin8.github.io/AwareHomePage/',
    },
    {
      title: 'UniBuzzy',
      description: (
        <>
          <p>
            As a university student, <strong>UniBuzzy</strong> is your go-to app to connect with other students 
            on campus, attend exciting events, and discuss the hottest news.
          </p>
          <p>
            The application was developed using <strong>Vue</strong> for the frontend and <strong>SpringBoot</strong> and <strong>OracleDB </strong> 
            for backend services, while integrating with external APIs for real-time event management. As the <strong>front-end development
            lead</strong>, I led the team by managing 
  <strong> 50+ weekly merges</strong> and organizing <strong>SCRUM meetings</strong> to ensure smooth 
  integration and timely feature delivery.
          </p>
        </>
      ),
      imageSrc: UniBuzzy,
      skills: [
        { name: 'Full-stack Development', color: '#A6CBF5' },
        { name: 'Leadership', color: '#BE88C6' }
      ],
      projectLink: 'https://nathan-lin8.github.io/UniBuzzyPage/',
    },
    {
      title: 'COVID-19 Detection Model',
      description: (
        <>
      <p>
        This is an AI-driven project for <strong>COVID-19 diagnosis</strong> using X-ray analysis, 
        achieving <strong>90% accuracy</strong> through research and optimization of various deep learning models.
      </p>
      <p>
        The model leverages a <strong>multimodal late fusion approach </strong>, combining <strong>image data </strong> with 
        <strong> tabular clinical data</strong> to enhance detection accuracy by incorporating diverse data sources. 
        Implemented in Python with deep learning frameworks such as TensorFlow, the model architecture 
        includes <strong>EfficientNet</strong>, <strong>VGG</strong>, and <strong>ResNet</strong> structures. Optimization techniques like 
        <strong> Monte Carlo dropout</strong> and <strong>Bayesian optimization</strong> are applied to further improve accuracy and model robustness.
      </p>
    </>
      ),
      imageSrc: Xray,
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Back-end Development', color: '#7C92FF' },
        { name: 'Research', color: '#93D375' }
      ],
      projectLink: 'https://drive.google.com/file/d/1pUhpecBQQaIxodwuMn0cq9fOp96guAEk/view?usp=sharing',
    },
    {
      title: 'Punchshot Pickleball',
      description: (
        <>
          <p>
            Punchshot Pickleball is a client-requested project managed by <strong>GT WebDev </strong> 
            that allows pickleball players to join and create teams and compete in local leagues.
          </p>
          <p>
            The app was built using <strong>ReactJS</strong> for the frontend, with backend services powered 
            by <strong>NodeJS/ExpressJS</strong> and data stored in <strong>MongoDB</strong>. It integrates 
            APIs like <strong>SendGrid</strong> for email notifications and <strong>Google Maps</strong> for geolocation.
          </p>
        </>
      ),
      imageSrc: Pickle,
      skills: [
        { name: 'Front-end Development', color: '#D6F693' }
      ],
      projectLink: 'https://github.com/Nathan-Lin8/Punchshot-Pickleball',
    },
    {
      title: 'Consultative Sales Assessment',
      description: (
        <>
          <p>
            This is a <strong>generative AI-powered application</strong> under Technology Liberation Corporation 
            that assesses the <strong>sales performance</strong> of a company’s sales team.
          </p>
          <p>
            The frontend is developed using <strong>ReactJS</strong>, while the backend is integrated using 
            <strong> NodeJS</strong>. The application also leverages <strong>Google Cloud APIs</strong> for data analysis, 
            with prompts generated using the <strong>GPT API</strong>.
          </p>
        </>
      ),
      imageSrc: CSA,
      skills: [
        { name: 'Internship', color: '#61dafb' },
        { name: 'Front-end Development', color: '#D6F693' },
        { name: 'Design', color: '#67C6C0' }
      ],
      projectLink: 'https://www.linkedin.com/company/technology-liberation-corporation/',
    },
  ];
  

  return (
    <div className="works">
      <h2 className="works-title">My Works</h2>
      <div className="polaroid-container">
        {projects.map((project, index) => (
          <ProjectPolaroid
            key={index}
            imageSrc={project.imageSrc}
            title={project.title}
            description={project.description}
            skills={project.skills}
            projectLink={project.projectLink}
          />
        ))}
      </div>
    </div>
  );
}

export default Works;
